/* eslint-disable @next/next/no-img-element */

import React, { useEffect, useState } from 'react';
import Link from 'next/link';

import Backend from '../../../../../utils/Backend';
import slugify from 'react-slugify';
import { formatCurrency, formatRange } from '../../../../../helper/vehicle_formatter';

import { event } from '../../../../../lib/gtag';

import LearnMoreButton from '../../../../LearnMoreButton';
import Loader from '../../../../Loader';

import styles from '../styles.module.scss';
import LeasePlanLogo from '../../../../../public/assets/images/lease-plan-logo.png';

interface IVehicleCard {
  vehicle: any,
  carOfWeek?: boolean
}

const LeasingVehicleCard = ({ vehicle, carOfWeek } : IVehicleCard) => {
  const [imageURL, setImageURL] = useState("")

  const {
    id, make, model, 
    fuel_type, range,
    lease_data: {
      business: {
        monthly_net
      }
    }
  } = vehicle; 

  useEffect(() => {
    setImageURL(Backend.getImage(vehicle, "28"))
  }, [vehicle])

  const generatedURL = `/lease-vehicle/${slugify(make)}/${slugify(model)}/${id}`;

  const renderVehicleDetails = () => {
    return (
      <div className={`${styles.vehicleDescription}`}>
        <p className={styles.vehicleType}>
          {
            fuel_type === "BEV" ? "All-Electric" :
            fuel_type === "PHEV" ? "Plug-in Hybrid" : fuel_type
          }
        </p>

        <h1 className={styles.vehicleTitle}>{ make + " " + model }</h1>

        <p className={styles.vehicleRange}>
          { parseInt(range) > 0 ? `Up to ${range}` : '- ' }km Range {formatRange(fuel_type)}
        </p>

        <p className={styles.mainteinanceText}>
          <span className={styles.bold}>
            {'Business (inc. maintenance)'} 
          </span>
          
          <br/>
          { formatCurrency(monthly_net) } per Month excl. VAT
        </p>

        <LearnMoreButton 
          className={styles.learnMoreBtn}
          onClick={() => event("clickedOnLearnMore")} 
          arrowColor={carOfWeek ? "#ED411E" : "#1A1C1F"}
        />
      </div>
    )
  }

  return (
    <Link href={generatedURL} passHref>
      <a className={
        `${styles.refHolder} ${carOfWeek && styles.specialRef}`
      }>
        { carOfWeek ? (
          <div className={`${styles.vehicleCard} ${styles.specialCard}`}>
            <div className={styles.specialOffer}>
              <img className={styles.leasePlanLogo} src={LeasePlanLogo.src} alt='LeasePlan Logo'/>

              <h1 className={styles.specialHeader}>Car of the Week</h1>
            </div>
            
            <div className={styles.vehicleHolder}>
              { renderVehicleDetails() }

              <div className={styles.specialImageHolder}>
                {
                  imageURL === ""
                  ? <Loader />
                  : <img className={styles.specialImage} src={imageURL} alt={make + " " + model} />
                }
              </div>
            </div>
          </div>
        ) 
        : 
        (
          <div className={`${styles.vehicleCard}`}>
            <div className={styles.vehicleImage} style={{ backgroundImage: `url(${imageURL})` }}>
              {
                imageURL === ""
                ? <Loader />
                : <>&nbsp;</>
              }
            </div>
            { renderVehicleDetails() }
          </div>
        )}
      </a>
    </Link>
  )
};

export default LeasingVehicleCard;
